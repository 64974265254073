/* Container for the entire settings screen */
.settings-container {
    padding: 20px;
    background-color: #f9f9f9;
    height: 100vh;
    overflow-y: auto;
  }
  
  /* Header title for the settings screen */
  .settings-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Each settings section (Account, Notifications, etc.) */
  .settings-section {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }
  
  /* Section titles (like 'Account Settings') */
  .settings-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Individual settings items */
  .settings-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  /* Remove border from the last item in each section */
  .settings-item:last-child {
    border-bottom: none;
  }
  
  /* Hover effect for settings items */
  .settings-item:hover {
    background-color: #f1f1f1;
  }
  
  /* Checkbox styling for toggles */
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  