.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
}

body {
  background: #f6f5f7;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: 0px 0 50px;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid transparent;
  background-color: black;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.video-transcript-container textarea {
  background-color: transparent;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  color: white;
  white-space:"nowrap";
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
  outline: none;
  height: 200px;
  /* height: 50px; */
}

.container {
  background-color: #fff;
  border-radius: 10px;
  /* Apply on desktop only */
  @media (min-width: 768px) {
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  }
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
    /* Apply on desktop only */
  @media (min-width: 768px) {
    margin: 0px 200px;
    /* display: flex; */
    /* position: absolute; */
  }

}

.sign-in-container {
  /* Apply on desktop only */
  @media (min-width: 768px) {
    /* left: 0; */
    width: 50%;
  }
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
  /* Apply on moibile only */
  /* @media (max-width: 768px) { */
  display: none;
  /* } */
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  /* Apply on desktop only */
  @media (min-width: 768px) {
    /* left: 0; */
    width: 50%;
  } 

}

.container.left-panel-active .sign-up-container {
  display: none;
  /* Apply on moibile only */
  @media (max-width: 768px) {
    display: none;
  }

}

.container.right-panel-active .sign-up-container {
  /* transform: translateX(100%); */
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;

  width: 100%;
  /* Apply on desktop only */
  @media (min-width: 768px) { 
  transform: translateX(0%);
  width: 50%
  } 
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #000000;
  background: -webkit-linear-gradient(to right, #000000, #000000);
  background: linear-gradient(to right, #000000, #000000);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.video-transcript-container {
  /* height: 450px; */
  width: 90%;
  margin: 10px auto;
  background-color: lightgray;
  border-radius: 10px;
  position: relative;
  top: 10px;
  border: 1px solid #000000;
}

.video-transcript-container .header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #000000;
  height: 30%;
  margin: auto;
  display: flex;
  padding: 0px 20px;
  color: white;
  align-items: center;
  position: relative;
  font-size: 0.6em;
  /* justify-content: center; */
}

.video-transcript-container p {
  padding: 0px 20px;
  color: white;
  /* justify-content: center; */
}

.video-transcript-container .actions {
  display: inline-block;
  position: absolute;
  right: 0px;
}

.video-transcript-container .actions svg{
  margin: 10px
}


footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: black;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}

.hide-on-mobile {
  display: block; /* Default: Show on larger screens */

  /* Media query for mobile devices (screens less than 768px wide) */
  @media (max-width: 768px) {
    display: none; /* Hide on mobile */
  }
}

.hide-on-desktop {
  display: block; /* Default: Show on larger screens */

  /* Media query for desktop devices (screens greater than 768px wide) */
  @media (min-width: 768px) {
    display: none; /* Hide on mobile */
  }
}

.hover-underline {
  text-decoration: none;
}

.hover-underline:hover {
  text-decoration: underline;
}

.subtitle {
  margin: "0px 0px";
  padding: "0px 0px";
  color: "white";
  opacity: "0.5"
}

.multiline-ellipsis {
  width: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* Number of lines */
}

.form-error {
  color: red;
}