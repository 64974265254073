.photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 10px;
  }
  
  .gallery-item {
    border-radius: 10px; /* Rounded edges */
    overflow: hidden; /* Ensure rounded corners for images */
  }
  
  .gallery-item img, .gallery-item video {
    width: 93%;
    height: auto;
    border-radius: 10px; /* Rounded edges for images */
    margin: 10px; /* Margin around images */
    transition: transform 0.3s, border 0.3s; /* Smooth transition for hover effects */
  }
  
  .gallery-item img:hover, .gallery-item video:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
    border: 3px solid red; /* Red border on hover */
  }

  .selected-item img {
    transform: scale(1.05); /* Slightly increase size on selected */
    border: 3px solid red; /* Red border on selected */
  }
  
  .selected-item video {
    border: 3px solid red; /* Red border on selected */
  }