/* Container for subscription screen */
.subscription-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title of the page */
  .subscription-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  /* Text for subscription details */
  .subscription-text {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Subscription detail entries */
  .subscription-detail {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .expiration {
    color: red;
  }
  
  /* Cancel button */
  .cancel-btn {
    background-color: #f44336;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
  
  .cancel-btn:hover {
    background-color: #d32f2f;
  }
  
  /* Transaction history */
  .transaction-history {
    margin-top: 20px;
  }
  
  .transaction-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .transaction-card {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  
  .no-transactions {
    text-align: center;
    color: #666;
  }
  
  /* No active plan message */
  .no-plan-text {
    text-align: center;
    font-size: 18px;
    color: #888;
    margin-top: 20px;
  }
  
  /* Subscribe button */
  .subscribe-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
  
  .subscribe-btn:hover {
    background-color: #45a049;
  }
  