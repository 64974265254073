.color-palette {
    display: block;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .color-display {
    width: 100%;
    height: 70%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #fff;
    font-weight: bold;
  }
  
  .dropdown-container {
    position: relative;
  }
  
  .dropdown-arrow {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    bottom: 30px; /* Position above the arrow */
    left: -10px;  /* Adjust position to center tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .dropdown-arrow:hover .tooltip {
    opacity: 1;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 30px; /* Adjust to position below the arrow */
    left: 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 200px; /* Set maximum height */
    overflow-y: auto;  /* Allow scrolling */
    z-index: 100;      /* Ensure it appears above other elements */
  }
  
  .color-option {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .color-option:hover {
    background-color: #f0f0f0;
    color: #333;
  }
  