/* Container to hold the button and popup */
.popup-container {
  position: relative;
  display: inline-block;
}

/* Menu button styles */
.menu-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: #45a049;
}

/* Popup menu container */
.popup-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 180px;
  overflow: hidden;
  z-index: 1000;
}

/* Individual option styles */
.popup-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-option:hover {
  background-color: #f1f1f1;
}

/* Icon styling */
.popup-icon {
  margin-right: 10px;
  font-size: 20px;
  color: #333;
}
