/* Full-screen overlay for the dialog */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  /* Dialog box container */
  .dialog-box {
    max-height: 50%;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    @media (min-width: 768px) { width: 60%;};
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    text-align: center;
  }
  
  /* Dialog text */
  .dialog-text {
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  /* Button container inside the dialog */
  .dialog-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  /* Dialog buttons */
  .dialog-btn {
    width: 48%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    border: none;
  }
  
  /* Cancel button with outline style */
  .cancel-btn {
    background-color: transparent;
    border: 2px solid #999;
    color: #333;
  }
  
  .cancel-btn:hover {
    background-color: #f1f1f1;
  }
  
  /* Confirm (OK) button with solid color */
  .confirm-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .confirm-btn:hover {
    background-color: #45a049;
  }
  
  /* Button to open the dialog */
  .open-dialog-btn {
    margin-top: 50px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
  }
  
  .open-dialog-btn:hover {
    background-color: #0056b3;
  }
  