/* Basic styles */
.open-drawer-btn {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001;
  }
  
  /* Overlay to darken background when drawer is open */
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  /* Drawer container */
  .drawer {
    position: fixed;
    top: 0;
    left: -100%; /* Initially hidden */
    width: 250px;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
    z-index: 1001;
  }
  
  /* Drawer when open */
  .drawer.open {
    left: 0; /* Slide in from the left */
  }
  
  /* Header with a close button */
  .drawer-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .close-drawer-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Options inside the drawer */
  .drawer-option {
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .drawer-option:hover {
    background-color: #f1f1f1;
  }
  